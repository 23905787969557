<template>
  <v-dialog v-model="dialog" width="70%">
    <template v-slot:activator="{ on, attrs }">
      <span class="link" v-bind="attrs" v-on="on">{{ buttonName }}</span>
    </template>

    <v-card style="overflow-x:hidden">
      <v-card-title class="text-h5 lighten-2">
        {{ fileName }}
      </v-card-title>

      <v-card-subtitle>
        Mostrando os 100 primeiros registros importados
      </v-card-subtitle>

      <v-card-text class="mt-2">
        <vue-json-pretty :deep="1" :data="data" highlight-mouseover-node show-line />
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer />
        <mf-action-buttons
          :primary-button="{
            text: 'Fechar',
            action: () => (dialog = false),
            isVisible: true
          }"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty
  },
  props: {
    buttonName: {
      type: String,
      default: 'Clique aqui para visualizar os dados importados'
    },
    fileName: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false
  })
}
</script>
