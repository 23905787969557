var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"70%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"link"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.buttonName))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticStyle:{"overflow-x":"hidden"}},[_c('v-card-title',{staticClass:"text-h5 lighten-2"},[_vm._v(" "+_vm._s(_vm.fileName)+" ")]),_c('v-card-subtitle',[_vm._v(" Mostrando os 100 primeiros registros importados ")]),_c('v-card-text',{staticClass:"mt-2"},[_c('vue-json-pretty',{attrs:{"deep":1,"data":_vm.data,"highlight-mouseover-node":"","show-line":""}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('mf-action-buttons',{attrs:{"primary-button":{
          text: 'Fechar',
          action: function () { return (_vm.dialog = false); },
          isVisible: true
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }