<template>
  <base-page-layout title="Calendário Mercatrade">
    <mf-card-container title="Gerar calendário" description="Importe os arquivos .csv com a base de varejistas e as ofertas para gerar o calendário">
      <template #headers>
        <mf-button label="Ajuda" icon="help" icon-position="right" color="primary" @click="goToManual" />
      </template>

      <div class="mt-4">
        <div class="my-4">
          <vertical-step class="mb-3" :number="1" text="Importe a base de varejistas" />
          <div class="mx-4">
            <div class="d-flex align-center" style="gap: 12px; width: 348px;">
              <import-csv button-name="Importar base .CSV" @done="$event => update($event, 'retailers')" @clear="clear('retailers')" />
              <mf-button label="Acessar template" icon="open_in_new" text icon-position="right" color="primary" @click="goToRetailersTemplate" />
            </div>
            <span class="text--secondary">{{ sheets.retailers.fileName }}</span>
          </div>

          <div class="ml-4">
            <preview-csv-dialog v-if="sheets.retailers.preview.length > 0" :file-name="sheets.retailers.fileName" :data="sheets.retailers.preview" />
          </div>
        </div>

        <div class="my-4">
          <vertical-step class="mb-3" :number="2" text="Selecione o tipo da campanha" />
          <div class="d-flex align-center" style="margin-left: 14px; width: 542px; gap: 14px;">
            <v-select
              v-model="selectedOfferType"
              :items="offersType"
              item-text="name"
              item-value="value"
              return-object
              hide-details
              label="Tipo da campanha"
              outlined
              :menu-props="{ offsetY: true }"
              @change="clear('offers')"
            />
            <mf-button label="Acessar template" icon="open_in_new" text icon-position="right" color="primary" @click="goToOffersTemplate" />
          </div>
        </div>

        <div class="my-4">
          <vertical-step class="mb-3" :number="3" text="Importe as ofertas" />
          <div class="mx-4">
            <import-csv button-name="Importar ofertas .CSV" @done="$event => update($event, 'offers')" @clear="clear('offers')" />
            <span>{{ sheets.offers.fileName }}</span>
          </div>

          <div class="ml-4">
            <preview-csv-dialog v-if="sheets.offers.preview.length > 0" :file-name="sheets.offers.fileName" :data="sheets.offers.preview" />
          </div>
        </div>

        <div class="my-4">
          <error-alert :errors="templateErrors.invalidNumberColumns" />
          <error-alert :errors="templateErrors.notFoundHeaders" />
          <error-alert :errors="templateErrors.invalidHeaders" />
          <error-alert :errors="campaignErrors.invalidTypes" />
        </div>

        <div class="my-4">
          <vertical-step class="my-4" :number="4" text="Insira o e-mail que receberá o calendário" />
          <div class="mx-4">
            <include-emails-table ref="includeEmailsTable" :emails.sync="emails" :max-emails-to-scroll="4" scroll-height="192px" />
          </div>
        </div>
      </div>
    </mf-card-container>

    <mf-action-buttons
      class="mt-2"
      :primary-button="{
        text: 'Gerar',
        action: generate,
        isVisible: true,
        isDisabled: $apollo.loading || this.sheets.retailers.data.length <= 0 || this.sheets.offers.data.length <= 0 || this.emails.length <= 0,
        isLoading: $apollo.loading || loading
      }"
    />
  </base-page-layout>
</template>

<script>
import Papa from 'papaparse'
import { MUTATION_CREATE_MERCATRADE_CALENDAR } from '@/modules/industry/graphql'
import PreviewCsvDialog from '@/modules/industry/components/PreviewCsvDialog.vue'
import { validateTemplate, convertAndValidateCampaigns, transformHeader } from '@/modules/industry/helpers/templateValidation.js'
import { calendarioMercatradeFields } from '@/modules/industry/helpers/templateFields.js'

export default {
  components: {
    BasePageLayout: () => import('@/components/base/BasePageLayout.vue'),
    VerticalStep: () => import('@/modules/industry/components/VerticalStep.vue'),
    ImportCsv: () => import('@/modules/industry/components/ImportCsv.vue'),
    IncludeEmailsTable: () => import('@/modules/industry/components/IncludeEmailsTable.vue'),
    ErrorAlert: () => import('@/modules/industry/mercapromo/views/components/ErrorAlert.vue'),
    PreviewCsvDialog
  },
  data: () => ({
    loading: false,
    sheets: {
      retailers: {
        data: [],
        fileName: '',
        preview: ''
      },
      offers: {
        data: [],
        fileName: '',
        preview: ''
      }
    },
    emails: [],
    offersType: [
      {
        name: 'Desconto Absoluto',
        value: 'general'
      },
      {
        name: 'Leve & Pague',
        value: 'takepay'
      },
      {
        name: 'Cashback',
        value: 'cashback'
      }
    ],
    selectedOfferType: {
      name: 'Desconto Absoluto',
      value: 'general'
    },
    templateErrors: {
      invalidNumberColumns: [],
      notFoundHeaders: [],
      invalidHeaders: []
    },
    campaignErrors: {
      invalidTypes: []
    }
  }),
  computed: {
    hasErrors() {
      return (
        Object.values(this.templateErrors).some(arrayError => arrayError.length > 0) ||
        Object.values(this.campaignErrors).some(arrayError => arrayError.length > 0)
      )
    }
  },
  mounted() {
    const { username } = this.$getLoggedUser()
    this.emails.push(username)
  },
  methods: {
    goToManual() {
      window.open('https://mercafacil.getoutline.com/doc/manual-backoffice-mercatrade-9LPk47mUBB#h-gerar-calendario-mercatrade', '_blank')
    },

    goToRetailersTemplate() {
      window.open('https://docs.google.com/spreadsheets/d/1AcEb0F8SC2jpkfzghNpoD8MS4ONQt0xbw7nKwD41wX8/edit?gid=612454057#gid=612454057', '_blank')
    },

    goToOffersTemplate() {
      let link = ''
      switch (this.selectedOfferType.value) {
        case 'general':
          link = 'https://docs.google.com/spreadsheets/d/1AcEb0F8SC2jpkfzghNpoD8MS4ONQt0xbw7nKwD41wX8/edit?usp=sharing'
          break
        case 'takepay':
          link = 'https://docs.google.com/spreadsheets/d/1Ee8z62vH08k3RHCuszG-aju_99EzHoYoontCBH7YzwA/edit?usp=sharing'
          break
        case 'cashback':
          link = 'https://docs.google.com/spreadsheets/d/1JkyFouRb7auOqwhw4zFmRavh6llj6_twzTHYyN1XXXQ/edit?usp=sharing'
          break
      }
      window.open(link, '_blank')
    },

    update(sheet, prop) {
      this.loading = true
      this.clear(prop)
      this.sheets[prop].fileName = sheet.fileName
      let validatingFields = []
      try {
        let parsed = Papa.parse(sheet.readed, { header: true, skipEmptyLines: true, dynamicTyping: true, transformHeader })
        if (prop === 'offers') {
          switch (this.selectedOfferType.value) {
            case 'general':
              validatingFields = calendarioMercatradeFields.commonOffer.concat(calendarioMercatradeFields.generalOffer)
              break
            case 'takepay':
              validatingFields = calendarioMercatradeFields.commonOffer.concat(calendarioMercatradeFields.takepayOffer)
              break
            case 'cashback':
              validatingFields = calendarioMercatradeFields.commonOffer.concat(calendarioMercatradeFields.cashbackOffer)
              break
          }
        } else if (prop === 'retailers') {
          validatingFields = calendarioMercatradeFields.retailer
        }
        this.templateErrors = validateTemplate(parsed, validatingFields)
        const { errors, converted } = convertAndValidateCampaigns(parsed, validatingFields)
        this.campaignErrors = errors
        if (this.hasErrors) return

        this.sheets[prop].data = converted
        this.sheets[prop].preview = JSON.parse(JSON.stringify(this.sheets[prop].data.slice(0, 100)))
        this.$alert({
          alert_message: `${this.sheets[prop].data.length} registros importados com sucesso`,
          alert_title: 'Sucesso!',
          alert_color: 'success',
          alert_icon: 'mdi-check-circle'
        })
      } catch (err) {
        console.error(err)
        this.$alert({
          alert_message: 'Nenhum registro importado',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle'
        })
      } finally {
        this.loading = false
      }
    },

    clearAll() {
      this.clear('offers')
      this.clear('retailers')
    },

    clear(prop) {
      this.sheets[prop].data = []
      this.sheets[prop].fileName = ''
      this.sheets[prop].preview = ''
      this.templateErrors = {
        invalidNumberColumns: [],
        notFoundHeaders: [],
        invalidHeaders: []
      }
      this.campaignErrors = {
        invalidTypes: []
      }
    },

    async generate() {
      this.loading = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_CREATE_MERCATRADE_CALENDAR,
          variables: {
            baseClient: this.sheets.retailers.data,
            baseCalendar: this.sheets.offers.data,
            emails: this.emails,
            offerType: this.selectedOfferType.value,
            fileFormat: 'CSV'
          },
          context: {
            uri: this.$microservicesUrls['asyncJobs']
          }
        })
        this.$snackbar({ message: 'Calendário enviado por e-mail', snackbarColor: 'success' })
        this.dialog = false
        this.clearAll()
      } catch (error) {
        this.$alert({
          alert_message: 'Erro ao gerar calendário',
          alert_title: 'Erro!',
          alert_color: 'error',
          alert_icon: 'mdi-close-circle',
          action: () => {
            const { errors } = error.networkError.result
            this.$errorDialog({
              title: errors.length > 1 ? 'Detalhes dos erros' : 'Detalhes do erro',
              errors: errors
            })
          },
          action_label: 'Ver detalhes'
        })

        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          const graphQLError = error.graphQLErrors[0]
          const details = graphQLError.message || 'Detalhes do erro indisponíveis'
          console.error(details)
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
