export const calendarioMercatradeFields = {
  commonOffer: [
    { name: 'UF', type: 'string', required: true },
    { name: 'Geo', type: 'string', required: true },
    { name: 'descricao_campanha', type: 'string', required: false },
    { name: 'descricao_produto', type: 'string', required: true },
    { name: 'retailers_discounts_sale_products_ean', type: 'int', required: true },
    { name: 'data_aceite', type: 'date', required: true },
    { name: 'oferta_data_inicio', type: 'date', required: true },
    { name: 'oferta_data_fim', type: 'date', required: true },
    { name: 'campanha_sms_data_inicio', type: 'date', required: false },
    { name: 'campanha_sms_data_fim', type: 'date', required: false },
    { name: 'campanha_sms_data_envio', type: 'date', required: false },
    { name: 'campanha_sms_hora_envio', type: 'string', required: false },
    { name: 'campanha_sms_text', type: 'string', required: false },
    { name: 'campanha_sms_product_text', type: 'string', required: false },
    { name: 'segmento_tipo', type: 'string', required: false },
    { name: 'segmento_dias', type: 'int', required: false },
    { name: 'segmento', type: 'string', required: false }
  ],
  generalOffer: [
    { name: 'preco_1', type: 'float', required: true },
    { name: 'preco_2', type: 'float', required: true },
    { name: 'rebate', type: 'float', required: false },
    { name: 'min', type: 'int', required: true },
    { name: 'max', type: 'int', required: true },
    { name: 'campanha_sms_price_1', type: 'string', required: false },
    { name: 'campanha_sms_price_2', type: 'string', required: false }
  ],
  takepayOffer: [
    { name: 'take', type: 'int', required: true },
    { name: 'pay', type: 'int', required: true },
    { name: 'max', type: 'int', required: true },
    { name: 'campanha_sms_take', type: 'string', required: false },
    { name: 'campanha_sms_pay', type: 'string', required: false }
  ],
  cashbackOffer: [
    { name: 'cashback_desconto_absoluto', type: 'int', required: true },
    { name: 'cashback_data_expiracao', type: 'date', required: true },
    { name: 'min', type: 'int', required: true },
    { name: 'max', type: 'int', required: true },
    { name: 'campanha_sms_cashback_desconto_absoluto', type: 'string', required: false },
    { name: 'campanha_sms_cashback_data_expiracao', type: 'string', required: false },
    { name: 'grupo_produtos', type: 'boolean', required: false }
  ],
  retailer: [
    { name: 'Status', type: 'string', required: false },
    { name: 'ID', type: 'int', required: true },
    { name: 'ID_CLIENTE2', type: 'int', required: false },
    { name: 'ID_starlord', type: 'int', required: false },
    { name: 'N_LOJAS', type: 'int', required: false },
    { name: 'N_LOJAS_GRUPO_ECO', type: 'int', required: false },
    { name: 'CLIENTE', type: 'string', required: false },
    { name: 'NOME_LOJA', type: 'string', required: false },
    { name: 'CNPJ_MATRIZ', type: 'int', required: false },
    { name: 'CNPJ', type: 'int', required: false },
    { name: 'CIDADE', type: 'string', required: false },
    { name: 'UF', type: 'string', required: true },
    { name: 'CSCX', type: 'string', required: false },
    { name: 'CLUSTER', type: 'string', required: false },
    { name: 'GEO', type: 'string', required: true },
    { name: 'CODIGO', type: 'string', required: false },
    { name: 'NOME_AMBEV', type: 'string', required: false },
    { name: 'ID_rede', type: 'int', required: false },
    { name: 'Rede', type: 'string', required: false }
  ]
}
